import axios from 'axios';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Spinner,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Switch from 'react-switch';
import toast from 'react-hot-toast';
import { withRouter} from 'react-router-dom';
import UploadAndDisplayImage from 'components/UploadAndDisplayImage';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useLocation } from 'react-router-dom';

const EditUser = () => {
  const [id, setId] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [roles, setRoles]  = useState([]);
  const [masjids, setMasjids]  = useState([]);
  const [phoneNumber, setPhoneNumber]  = useState("");
  const [gender, setGender]  = useState(null);
  const [loading, setLoading] = useState(true);
  const [role, setRole]  = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [modal, setModal] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [homeMasjid, setHomeMasjid] = useState(null);
  const [imageLoader, setImageLoader] = useState(false);

  useEffect(() => {
    const fetchRole = async () => {
        try {
            const data2 = await axios.get(`/admin/near/masjid`);
            const {data}  = await axios.get(`/admin/role`);
            if (data.error) {
                toast.error(data.message);
            } else {
                if (data.error) {
                    toast.error(data.message);
                } else {
                    setRoles(data.payload)
                    if (data2.data.error) {
                      toast.error(data.message);
                    } else {
                      setMasjids(data2.data.payload)
                    }
                }
            }
          } catch (error) {
            if (error && error.message && String(error.message).includes('401')) {
              localStorage.removeItem('@auth_token');
              localStorage.removeItem('@is_Vendor');
              window.location.reload();
            }
            else{
              toast.error("Something went wrong");
              console.log(error)
            }
          }
    }
    fetchRole();
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('user');

  useEffect(() => {
    const fetchUser = async () => {
      if (userId) {
        try {
          const { data } = await axios.get(`/admin/user/${userId}`);
          if (data.error) {
            setError(data.message);
          } else {
            setId(data?.payload?._id);
            setFirstName(data?.payload?.firstName);
            setLastName(data?.payload?.lastName);
            setEmail(data?.payload?.email);
            setIsActive(data?.payload?.isActive);
            setPhoneNumber(data?.payload?.phoneNumber);
            setGender(data?.payload?.gender);
            setRole(data?.payload?.role)
            setProfilePic(data?.payload?.profilePic)
            setHomeMasjid(data?.payload?.homeMasjid)
          }
        } catch (error) {
          if (error && error.message && String(error.message).includes('401')) {
            localStorage.removeItem('@auth_token');
            localStorage.removeItem('@is_Vendor');
            window.location.reload();
          }
          else{
            toast.error("Something went wrong");
            console.log(error)
          }  
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUser();
  }, [userId]);


  const updateUser = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      let payload = {
        id,
        firstName,
        lastName,
        email,
        profilePic,
        phoneNumber,
        profilePic,
        gender,
        role,
        homeMasjid,
        isActive: isActive ? true :false,
      }
     
        const { data } = await axios.post('/admin/update/user', payload);
        if (data.error) {
          setLoading(false);
          return toast.error(data.message);
        }
        else {
          setLoading(false);
          toast.success(data.message);
          window.location.href = "/admin/users"
        } 
   
    } catch (error) {
      setLoading(false);
      if (error && error.message && String(error.message).includes('401')) {
        localStorage.removeItem('@auth_token');
        localStorage.removeItem('@is_Vendor');
        window.location.reload();
      }
      else{
        toast.error("Something went wrong");
        console.log(error)
      }
    }
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const addPhoto = async () => {
    try {
      if(!newImage){
        toast.error('Image is required');
      }
      else{
        setProfilePic(newImage)
        setModal(false);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to add');
    }
  };

  return (
    <Page
      title="Add Masjid"
      breadcrumbs={[{ name: 'tables', active: true }]}
      className="EditUser"
    >
      {loading ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <Spinner color="primary" />
          </div>
        ):
      <Row>
        <Col>
          <Card>
            <CardHeader>Update User</CardHeader>
            <CardBody>
              <Form onSubmit={updateUser}>
                <FormGroup style={{marginRight:"100px",display:"flex", alignItems: "center", justifyContent:"flex-end"}} check>
                    <Label style={{marginRight : "10px"}} check>
                    {isActive ? "Active": "Inactive"}
                    </Label>
                    <Switch
                        onChange={e => setIsActive(!isActive)}
                        checked={isActive}
                      />
              </FormGroup>
                <FormGroup row>
                  <Label for="name" sm={3}>
                    First Name
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      onChange={e => setFirstName(e.target.value)}
                      value={firstName}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="name" sm={3}>
                    Last Name
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      onChange={e => setLastName(e.target.value)}
                      value={lastName}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="address" sm={3}>
                    Email
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={e => setEmail(e.target.value)}
                      value={email}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="lat" sm={3}>
                    Phone Number
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      onChange={e => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>Role</Label>
                  <Col sm={7}>
                    <Input
                      type="select"
                      value={role}
                      onChange={e => setRole(e.target.value)}
                    >
                      <option value="">Select Role</option>
                      {roles.map(role => (
                        <option key={role?._id} value={role?.name} selected = {role}>
                          {role?.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>Home Masjid</Label>
                  <Col sm={7}>
                    <Input
                      type="select"
                      value={homeMasjid}
                      onChange={e => setHomeMasjid(e.target.value)}
                    >
                      <option value="">Select Masjid</option>
                      {masjids.map(masjid => (
                        <option key={masjid._id} value={masjid._id} selected = {homeMasjid}>
                          {masjid.masjidName}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>Gender</Label>
                  <Col sm={7}>
                    <Input
                      type="select"
                      value={gender}
                      onChange={e => setGender(e.target.value)}
                    >
                      <option value="">Select Mozan</option>
                      <option value="m">Male</option>
                      <option value="f">Female</option>
                      <option value="o">Other</option>
                      
                    </Input>
                  </Col>
                </FormGroup>
                
                <Row>
                <Col>
                    <Card className="mt-4">
                    <div style={{display:"flex", justifyContent: "space-between", margin: "20px", marginBottom: "0"}}>
                        <h5 style={{
                            height: 'fit-content',
                            marginTop: "10px",
                            fontSize: "16px"
                        }}>profilePic</h5>
                        <Button color="primary" className="ml-auto" onClick={toggleModal}>
                            {(profilePic ? "Update profilePic": "Add profilePic")}
                        </Button>
                    </div>
                    <CardBody>
                        <div className="img-grid">
                        {(profilePic) && 
                            <Card className="photo-card">
                            <img src={profilePic} alt={`Masjid profilePic`} className="photo" />
                            {/* <Button color="danger" onClick={() => setProfilePic("")}>
                                <FaTrash />
                            </Button> */}
                            </Card>}
                        </div>
                    </CardBody>
                    </Card>
                </Col>
                </Row>
                <FormGroup style= {{display: "Flex", justifyContent: "space-around", marginTop: "10px"}}>
                    <Button>update</Button>
                </FormGroup>
              </Form>
            </CardBody>
            <Modal isOpen={modal} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}>Add Photo</ModalHeader>
              <ModalBody>
              {imageLoader ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner color="primary" />
                </div>
                ):(
                <UploadAndDisplayImage url={newImage} setUrl={setNewImage} setImageLoader={setImageLoader} />
                )
              }
              </ModalBody>
              <ModalFooter>
                {newImage && <Button color="primary" onClick={addPhoto}>
                  Add
                </Button>}{' '}
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </Card>
        </Col>
      </Row>
      }
       <style jsx>{`
        .img-grid {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
        }

        .photo-card {
          width: calc(33.333% - 10px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        .photo-card .photo {
          width: 100%;
          height: 150px;
          object-fit: cover;
        }
      `}</style>
    </Page>
  );
};

export default withRouter(EditUser);
