import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Table,
  Spinner,
  Button,
  Col,
  Input
} from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';

const Masjids = () => {
  const [masjids, setMasjids] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flterValue, setFilterValue] = useState("all");

  const deleteMasjid = async id => {
    try {
      setLoading(true)
      const { data } = await axios.delete(`/admin/delete/masjid/${id}`);
      if (data.error) {
        setLoading(false);
        return toast.error(data.message);
      }
      else {
        setLoading(false);
        let temp = [...masjids];
        let m = masjids.filter(x => x._id.toString() !== id.toString());
        setMasjids(m);
        toast.success(data.message);
      }
    } catch (error) {
      setLoading(false);
      if (error && error.message && String(error.message).includes('401')) {
        localStorage.removeItem('@auth_token');
        localStorage.removeItem('@is_Vendor');
        window.location.reload();
      }
      else{
        console.log(error)
      }
    }
  };

  const updateMasjidStatus = async (id, status, isApproved) => {
    try {
      setLoading(true)
      const { data } = await axios.post(`/admin/update/masjid`, {id, isApproved: !isApproved});
      if (data.error) {
        setLoading(false);
        return toast.error(data.message);
      }
      else {
        setLoading(false);
        let temp = [...masjids];
        let m = temp.find(x => x._id.toString() == id.toString());
        m.isApproved = !m.isApproved;
        setMasjids(temp);
        toast.success(status + " Sucessfully");
      }
    } catch (error) {
      setLoading(false);
      if (error && error.message && String(error.message).includes('401')) {
        localStorage.removeItem('@auth_token');
        localStorage.removeItem('@is_Vendor');
        window.location.reload();
      }
      else{
        toast.error("Something went wrong");
        console.log(error)
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get('/admin/all/masjids?status='+flterValue);
        if (data.error) {
          setLoading(false);
          toast.error(data.message);
        } else {
          setLoading(false);
          setMasjids(data.payload);
        }
      } catch (error) {
        setLoading(false);
        if (error && error.message && String(error.message).includes('401')) {
          localStorage.removeItem('@auth_token');
          localStorage.removeItem('@is_Vendor');
          window.location.reload();
        }
        else{
          toast.error("Something went wrong");
          console.log(error)
        }
      }
    };

    fetchData(); // Call the async function immediately
  }, [flterValue]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get('/admin/all/masjids');
        if (data.error) {
          setLoading(false);
          toast.error(data.message);
        } else {
          setLoading(false);
          setMasjids(data.payload);
        }
      } catch (error) {
        setLoading(false);
        if (error && error.message && String(error.message).includes('401')) {
          localStorage.removeItem('@auth_token');
          localStorage.removeItem('@is_Vendor');
          window.location.reload();
        }
        else{
          toast.error("Something went wrong");
          console.log(error)
        }
      }
    };

    fetchData(); // Call the async function immediately
  }, []);

  return (
          <Card style={{margin: "15px"}}>
            <div style={{display:"flex", justifyContent: "space-between", margin: "20px", marginBottom: "0"}}>
              <h5 style={{
                  height: 'fit-content',
                  marginTop: "10px",
                  fontSize: "16px"
                }}>ALL MASAJIDS</h5>
              
              <div style={{display:"flex", justifyContent: "center", margin: "20px", marginBottom: "0"}}>
                <Col sm={6}>
                  <Input
                    type="select"
                    value={flterValue}
                    onChange={e => setFilterValue(e.target.value)}
                  >
                    <option value="all">All</option>
                    <option value="approved">Approved</option>
                    <option value="unapproved">UnApproved</option>
                  </Input>
                </Col>
              
                <Link
                  to="/masjid/add"
                  className="btn btn-outline-primary"
                  style={{
                    height: 'fit-content'
                  }}
                >
                Add New Masjid
                </Link>
              </div>
            </div>
            {loading ? 
              <div className="d-flex justify-content-center align-items-center vh-100">
                <Spinner color="primary" />
              </div>
              : 
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Masjid Name</th>
                      <th>Address</th>
                      <th>Imam</th>
                      <th>Mozan</th>
                      <th>Status</th>
                      <th>Details</th>
                      <th style={{textAlign:"center"}}>Action</th>
                      <th style={{textAlign:"center"}}>Marked As</th>
                    </tr>
                  </thead>
                  <tbody>
                    {masjids.map((masjid, index) => (
                      <tr key={masjid._id}>
                        <th scope="row">{index + 1}</th>
                        <td>{masjid.masjidName}</td>
                        <td>{masjid?.address}</td>
                        <td>{masjid?.imam}</td>
                        <td>{masjid?.mozan}</td>
                        <td>{masjid?.isApproved ? 'Approved' : 'Unapproved'}</td>
                        {
                          <td>
                            <Link
                              onClick = { () => { window.location.href ='/masjid/details?masjid='+masjid._id}}
                              to = ""
                              className="btn btn-outline-primary"
                              style={{
                                height: 'fit-content',
                                margin: 'auto 0px',
                              }}
                            >
                              Details
                            </Link>
                          </td>
                        }
                        {
                          <td>
                            <Button color="danger" onClick= {() => deleteMasjid(masjid._id)} >
                              <FaTrash />
                            </Button>
                          </td>
                        }
                        {
                          <td>
                            <Link
                                to = ""
                                onClick= {() => updateMasjidStatus(masjid._id, (masjid?.isApproved ? 'UnApproved' : 'Approved'),  masjid?.isApproved)} 
                                className= {masjid?.isApproved ? "btn btn-outline-danger" : "btn btn-outline-success"}
                                style={{
                                  height: 'fit-content',
                                  margin: 'auto 0px',
                                  width: "115px"
                                }}
                              >
                              {masjid?.isApproved ? 'UnApprove' : 'Approve' }
                            </Link>
                          </td>
                        }
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            }
          </Card>
  );
};

export default withRouter(Masjids);
