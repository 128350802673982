import axios from 'axios';
import Page from 'components/Page';
import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Spinner,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { withRouter } from 'react-router-dom';

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validatePassword = (password) => {
    const minLength = 8;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    if (password.length < minLength) {
      return `Password must be at least ${minLength} characters long`;
    }
    return '';
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const passwordError = validatePassword(password);
    if (passwordError) {
      toast.error(passwordError);
      return;
    }
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
    setLoading(true);
    try {
      // Replace the URL with the correct API endpoint
      const response = await axios.post('/admin/change/password', {
        currentPassword,
        newPassword: password,
      });

      setConfirmPassword('');
      setPassword('');
      setCurrentPassword('');
      if(response.data.error){
        toast.error(response.data.message);
      }
      else{
        toast.success(response.data.message || 'Password changed successfully');
      }
    } catch (error) {
      if (error && error.message && String(error.message).includes('401')) {
        localStorage.removeItem('@auth_token');
        localStorage.removeItem('@is_Vendor');
        window.location.reload();
      }
      else{
        toast.error("Something went wrong");
        console.log(error)
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page
      title="Change Password"
      breadcrumbs={[{ name: 'settings', active: true }]}
      className="ChangePassword"
    >
      {loading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner color="primary" />
        </div>
      ) : (
        <Row>
          <Col>
            <Card>
              <CardHeader>Change Password</CardHeader>
              <CardBody>
                <Form onSubmit={handleChangePassword}>
                  <FormGroup row>
                    <Label for="currentPassword" sm={3}>
                      Current Password
                    </Label>
                    <Col sm={10}>
                      <InputGroup>
                        <Input
                          type={showCurrentPassword ? 'text' : 'password'}
                          name="currentPassword"
                          placeholder="Current Password"
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          value={currentPassword}
                          required
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                            {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="newPassword" sm={3}>
                      New Password
                    </Label>
                    <Col sm={10}>
                      <InputGroup>
                        <Input
                          type={showNewPassword ? 'text' : 'password'}
                          name="newPassword"
                          placeholder="New Password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          required
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText onClick={() => setShowNewPassword(!showNewPassword)}>
                            {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="confirmPassword" sm={3}>
                      Confirm Password
                    </Label>
                    <Col sm={10}>
                      <InputGroup>
                        <Input
                          type={showConfirmPassword ? 'text' : 'password'}
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          value={confirmPassword}
                          required
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup className="d-flex justify-content-around mt-3">
                    <Button color="primary" type="submit">
                      Change Password
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Page>
  );
};

export default withRouter(ChangePassword);
