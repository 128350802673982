import axios from 'axios';
import React, { useState } from 'react';
import toast from 'react-hot-toast';

const UploadAndDisplayImage = ({ url, setUrl, isVideo = false, setImageLoader }) => {
  const _uploadImage = async image => {
    setImageLoader(true)
    var formData = new FormData();
    if (isVideo) {
      formData.append('video', image);
    } else {
      formData.append('image', image);
    }
    try {
      const { data } = await axios.post(
        isVideo ? '/upload/assets/video' : '/upload/assets/image',
        formData,
      );

      if (data.error) {
        setImageLoader(false)
        return toast.error(data.message);
      }
      else {
        toast.success(data.message);
        setUrl(data?.payload);
        setImageLoader(false)
      }
    } catch (error) {
      setImageLoader(false)
      if (error && error.message && String(error.message).includes('401')) {
        localStorage.removeItem('@auth_token');
        localStorage.removeItem('@is_Vendor');
        window.location.reload();
      }
      else{
        console.log(error)
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div>
      {url && (
        <div>
          {isVideo ? (
            <video
              muted
              controls
              className="img-thumbnail"
              style={{
                height: 300,
                width: 300,
              }}
              src={url}
            />
          ) : (
            <img
              className="img-thumbnail"
              style={{
                height: 300,
                width: 300,
              }}
              src={url}
            />
          )}
        </div>
      )}

      <input
        type="file"
        accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
        name="myImage"
        onChange={event => {
          console.log(event.target.files[0]);
          _uploadImage(event.target.files[0]);
        }}
      />
    </div>
  );
};

export default UploadAndDisplayImage;
