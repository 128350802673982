import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Table,
  Spinner,
  Button,
  Col,
  Input
} from 'reactstrap';
import { FaTrash, FaEdit } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';
import './Users.css'; // Import the CSS file
import dummyMale from "../../assets/male_dummy.jpg"
import dummyFemale from "../../assets/female_dummy.jpg"
import dummyOther from "../../assets/other_dummy.png"

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("all");

  const deleteUser = async id => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`/admin/delete/user/${id}`);
      if (data.error) {
        setLoading(false);
        return toast.error(data.message);
      } else {
        setLoading(false);
        let m = users.filter(x => x._id.toString() !== id.toString());
        setUsers(m);
        toast.success(data.message);
      }
    } catch (error) {
      setLoading(false);
      if (error && error.message && String(error.message).includes('401')) {
        localStorage.removeItem('@auth_token');
        localStorage.removeItem('@is_Vendor');
        window.location.reload();
      }
      else{
        toast.error("Something went wrong");
        console.log(error)
      }
    }
  };

  const updateUserStatus = async (id, status, isActive) => {
    try {
      console.log(id)
      setLoading(true);
      const { data } = await axios.post(`/admin/update/user`, { id, isActive: !isActive });
      if (data.error) {
        setLoading(false);
        return toast.error(data.message);
      } else {
        setLoading(false);
        let temp = [...users];
        let m = temp.find(x => x._id.toString() === id.toString());
        m.isActive = !m.isActive;
        setUsers(temp);
        toast.success(status + " Successfully");
      }
    } catch (error) {
      setLoading(false);
      if (error && error.message && String(error.message).includes('401')) {
        localStorage.removeItem('@auth_token');
        localStorage.removeItem('@is_Vendor');
        window.location.reload();
      }
      else{
        toast.error("Something went wrong");
        console.log(error)
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get('/admin/all/users?status=' + filterValue);
        if (data.error) {
          setLoading(false);
          toast.error(data.message);
        } else {
          setLoading(false);
          setUsers(data.payload);
        }
      } catch (error) {
        setLoading(false);
        if (error && error.message && String(error.message).includes('401')) {
          localStorage.removeItem('@auth_token');
          localStorage.removeItem('@is_Vendor');
          window.location.reload();
        }
        else{
          toast.error("Something went wrong");
          console.log(error)
        }
      }
    };

    fetchData(); // Call the async function immediately
  }, [filterValue]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get('/admin/all/users');
        if (data.error) {
          setLoading(false);
          toast.error(data.message);
        } else {
          setLoading(false);
          setUsers(data.payload);
        }
      } catch (error) {
        setLoading(false);
        if (error && error.message && String(error.message).includes('401')) {
          localStorage.removeItem('@auth_token');
          localStorage.removeItem('@is_Vendor');
          window.location.reload();
        }
        else{
          toast.error("Something went wrong");
          console.log(error)
        }
      }
    };

    fetchData(); // Call the async function immediately
  }, []);

  return (
    <Card style={{ margin: "15px" }}>
      <div style={{ display: "flex", justifyContent: "space-between", margin: "20px", marginBottom: "0" }}>
        <h5 style={{
          height: 'fit-content',
          marginTop: "10px",
          fontSize: "16px"
        }}>ALL Users</h5>

        <Col sm={3}>
          <Input
            type="select"
            value={filterValue}
            onChange={e => setFilterValue(e.target.value)}
          >
            <option value="all">All</option>
            <option value="active">Active</option>
            <option value="inactive">InActive</option>
          </Input>
        </Col>
      </div>
      {loading ?
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner color="primary" />
        </div>
        :
        <CardBody>
          <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Avatar</th>
                <th>Name</th>
                <th>Masjid</th>
                <th>Role</th>
                <th>Phone #</th>
                <th>Gender</th>
                <th>Status</th>
                <th style={{ textAlign: "center" }}>Action</th>
                <th style={{ textAlign: "center" }}>Marked As</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user._id}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    <img 
                      src={user?.profilePic || (user?.gender === 'm' ? dummyMale : (user?.gender === 'f') ? dummyFemale : dummyOther)} 
                      alt="avatar" 
                      className="avatar-circle" 
                    />
                  </td>
                  <td>{user.firstName + " " + user.lastName}</td>
                  <td>{user?.masjid?.masjidName}</td>
                  <td>{user?.role}</td>
                  <td>{user?.phoneNumber}</td>
                  <td>{(user?.gender === "m") ? "Male" : (user?.gender === "F") ? "Female" : "Other"}</td>
                  <td>{user?.isActive ? 'Active' : 'Inactive'}</td>
                  <td>
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0" }}>
                      <Button color="danger" onClick={() => deleteUser(user._id)} >
                        <FaTrash />
                      </Button>
                      <Button style={{marginLeft: "10px"}} color="primary" onClick = { () => { window.location.href ='/edit/user?user='+user._id}}>
                        <FaEdit />
                      </Button>
                    </div>
                  </td>
                  <td>
                    <Link
                      onClick={() => updateUserStatus(user._id, (user?.isActive ? 'Inactive' : 'Active'), user?.isActive)}
                      className={user?.isActive ? "btn btn-outline-danger" : "btn btn-outline-success"}
                      style={{
                        height: 'fit-content',
                        margin: 'auto 0px',
                        width: "115px"
                      }}
                    >
                      {user?.isActive ? 'Inactive' : 'Active'}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      }
    </Card>
  );
};

export default withRouter(Users);
