import axios from 'axios';
import {STATE_LOGIN} from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import { BASE_URL } from './utils/constants';
import 'react-datetime/css/react-datetime.css';

import { Toaster } from 'react-hot-toast';
import Users from 'pages/newPages/Users';
import EditUser from 'pages/newPages/EditUser';
import ChangePassword from 'pages/newPages/ChangePassword';
import Masjids from 'pages/newPages/Masjid';
import MasjidAdd from 'pages/newPages/Masjid/add'
import MasjidEdit from 'pages/newPages/Masjid/edit';
import MasjidDetails from 'pages/newPages/Masjid/detail';
import success from 'pages/fatoraResponse/success';
import error from 'pages/fatoraResponse/error';
const AlertPage = React.lazy(() => import('pages/AlertPage'));
const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
const BadgePage = React.lazy(() => import('pages/BadgePage'));
const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
const CardPage = React.lazy(() => import('pages/CardPage'));
const ChartPage = React.lazy(() => import('pages/ChartPage'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
const FormPage = React.lazy(() => import('pages/FormPage'));
const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
const ModalPage = React.lazy(() => import('pages/ModalPage'));
const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
const TablePage = React.lazy(() => import('pages/TablePage'));
const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
const WidgetPage = React.lazy(() => import('pages/WidgetPage'));

axios.defaults.baseURL = BASE_URL;

const token = localStorage.getItem('@auth_token');

if (token) {
  axios.defaults.headers.common = { Authorization: `${token}` };
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: localStorage.getItem('@auth_token'),
    };
  }

  render() {
    return (
      <BrowserRouter basename="/admin">
        <GAListener>
          <Toaster position="top-right" reverseOrder={false} />{' '}
          <Switch>
            <Route
              exact
              path="/fatora-response-pages/success"
              component={success}
            />
            <Route
              exact
              path="/fatora-response-pages/error"
              component={error}
            />
            {this.state.isAuthenticated ? (
              <MainLayout breakpoint={this.props.breakpoint}>
                <React.Suspense fallback={<PageSpinner />}>
                  <Route exact path="/" component={DashboardPage} />
                  <Route exact path="/change/password" component={ChangePassword} />
                  <Route exact path="/users" component={Users} />
                  <Route exact path="/edit/user" component={EditUser} />
                  <Route exact path="/masjid/add" component={MasjidAdd} />
                  <Route exact path="/edit/masjid/details" component={MasjidEdit} />
                  <Route exact path="/masjids" component={Masjids} />
                  <Route exact path="/masjid/details" component={MasjidDetails} />
                  <Route exact path="/login-modal" component={AuthModalPage} />
                  <Route exact path="/buttons" component={ButtonPage} />
                  <Route exact path="/cards" component={CardPage} />
                  <Route exact path="/widgets" component={WidgetPage} />
                  <Route exact path="/typography" component={TypographyPage} />
                  <Route exact path="/alerts" component={AlertPage} />
                  <Route exact path="/tables" component={TablePage} />
                  <Route exact path="/badges" component={BadgePage} />
                  <Route
                    exact
                    path="/button-groups"
                    component={ButtonGroupPage}
                  />
                  <Route exact path="/dropdowns" component={DropdownPage} />
                  <Route exact path="/progress" component={ProgressPage} />
                  <Route exact path="/modals" component={ModalPage} />
                  <Route exact path="/forms" component={FormPage} />
                  <Route
                    exact
                    path="/input-groups"
                    component={InputGroupPage}
                  />
                  <Route exact path="/charts" component={ChartPage} />
                </React.Suspense>
              </MainLayout>
            ) : (
              <>
                <Redirect
                  to={{
                    pathname: '/login',
                  }}
                />
                <LayoutRoute
                  exact
                  path="/login"
                  layout={EmptyLayout}
                  component={props => (
                    <AuthPage {...props} authState={STATE_LOGIN} />
                  )}
                />
              </>
            )}
            <Redirect to="/" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
