import axios from 'axios';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa'; // Import trash icon for deleting timing
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Spinner,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Switch from 'react-switch';
import toast from 'react-hot-toast';
import { withRouter} from 'react-router-dom';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useLocation } from 'react-router-dom';

const EditMasjid = () => {
  const [imams, setImams] = useState([]);
  const [mozans, setMozans] = useState([]);
  const [name, setName] = useState(null);
  const [address, setAddress] = useState(null);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [isApproved, setIsApproved] = useState(false);
  const [timing, setTiming]  = useState([]);
  const [loading, setLoading] = useState(true);
  const [imam, setImam]  = useState("");
  const [mozan, setMozan] = useState("");
  const [id, setId] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const masjidId = queryParams.get('masjid');

  useEffect(() => {
    const fetchMasjid = async () => {
      if (masjidId) {
        try {
          const { data } = await axios.get(`/admin/masjid/${masjidId}`);
          const data2  = await axios.get(`/admin/all/imamsmoazn`);
          if (data.error) {
            toast.error(data.message);
          } else {
            setId(data.payload._id);
            setName(data.payload.masjidName);
            setIsApproved(data.payload.isApproved);
            setAddress(data.payload.address);
            setLat(data.payload.location?.coordinates[1]);
            setLong(data.payload.location?.coordinates[0]);
            setImam(data.payload?.imam?._id)
            setMozan(data.payload?.mozan?._id)

            if(data.payload.namazTimings && data.payload.namazTimings.length > 0){
              let temp = [];
              data.payload.namazTimings.forEach(x=> {
                let time = x.namazTime.split(" ");
                let ampm = time[1];
                let hours = time[0].split(":")[0]
                let min = time[0].split(":")[1]
                
                temp.push({
                  _id: uuidv4(),
                  namazName: x.namazName,
                  hours: hours,
                  minutes: min,
                  ampm: ampm,
                });
              })

              setTiming(temp);
            }

            if (data2.data.error) {
              toast.error(data2.data.message);
            } else {
                setMozans(data2.data.payload.mozans)
                setImams(data2.data.payload.imams)
            }
          }
        } catch (error) {
          if (error && error.message && String(error.message).includes('401')) {
            localStorage.removeItem('@auth_token');
            localStorage.removeItem('@is_Vendor');
            window.location.reload();
          }
          else{
            toast.error("Something went wrong");
            console.log(error)
          }
        } finally {
          setLoading(false);
        }
      }
    };

    fetchMasjid();
  }, [masjidId]);

  const handleTimingChange = (index, field, value) => {
    const updatedTiming = [...timing];
    if(field == "namazName"){
      if(updatedTiming.find(x => value == x.namazName)){
        toast.error(value+" Namaz Time Already Exist");
      }
      else{
        updatedTiming[index][field] = value;
        setTiming(updatedTiming);
      }
    }
    else{
      updatedTiming[index][field] = value;
      setTiming(updatedTiming);
    }
  };

  const handleAddTiming = () => {
    const newTiming = {
      _id: uuidv4(),
      namazName: '',
      hours: '',
      minutes: '',
      ampm: '',
    };
  
    setTiming(prevTiming => [...prevTiming, newTiming]);
  };

  const handleRemoveTiming = index => {
    setTiming(prevTiming => prevTiming.filter((_, i) => i !== index));
  };

  const updateMasjid = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      let namazTimings = [];
      timing.forEach(x=> {
        if(x.namazName && x.hours && x.minutes && x.ampm){
          namazTimings.push({
            "namazName": x.namazName,
            "namazTime": x.hours+":"+x.minutes+" "+x.ampm
          })
        }
      });
      let payload = {
        imam,
        mozan,
        masjidName: name,
        address,
        lat,
        long,
        namazTimings,
        isApproved: isApproved ? true :false,
        id,
      }
        const { data } = await axios.post('/admin/update/masjid', payload);

        if (data.error) {
          setLoading(false);
          return toast.error(data.message);
        }
        else {
          setLoading(false);
          toast.success(data.message);
          window.location.href = "/"
        }
      
      } catch (error) {
        setLoading(false);
        if (error && error.message && String(error.message).includes('401')) {
          localStorage.removeItem('@auth_token');
          localStorage.removeItem('@is_Vendor');
          window.location.reload();
        }
        else{
          toast.error("Something went wrong");
          console.log(error)
        }
      }
  };

  return (
    <Page
      title="Add Masjid"
      breadcrumbs={[{ name: 'tables', active: true }]}
      className="EditMasjid"
    >
      {loading ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <Spinner color="primary" />
          </div>
        ):
      <Row>
        <Col>
          <Card>
            <CardHeader>Update Masjid Details</CardHeader>
            <CardBody>
              <Form onSubmit={updateMasjid}>
              <FormGroup style={{display:"flex", alignItems: "center", justifyContent:"flex-end"}} check>
                    <Label style={{marginRight : "10px"}} check>
                    {isApproved? "Active": "Inactive"}
                    </Label>
                    <Switch
                        onChange={() => setIsApproved(!isApproved)}
                        checked={isApproved}
                      />
              </FormGroup>
              <FormGroup row>
                  <Label for="name" sm={3}>
                    Name
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Name"
                      onChange={e => setName(e.target.value)}
                      value={name}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="address" sm={3}>
                    Address
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="address"
                      placeholder="Address"
                      onChange={e => setAddress(e.target.value)}
                      value={address}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="lat" sm={3}>
                    Latitude
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="lat"
                      placeholder="Latitude"
                      onChange={e => setLat(e.target.value)}
                      value={lat}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="long" sm={3}>
                    Longitude
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="long"
                      placeholder="Longitude"
                      onChange={e => setLong(e.target.value)}
                      value={long}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>Imam</Label>
                  <Col sm={7}>
                    <Input
                      type="select"
                      value={imam}
                      onChange={e => setImam(e.target.value)}
                    >
                      <option value="">Select Imam</option>
                      {imams.map(imam => (
                        <option key={imam._id} value={imam._id} selected = {imam}>
                          {imam.firstName + " " + imam.lastName}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>Mozan</Label>
                  <Col sm={7}>
                    <Input
                      type="select"
                      value={mozan}
                      onChange={e => setMozan(e.target.value)}
                    >
                      <option value="">Select Mozan</option>
                      {mozans.map(mozan => (
                        <option key={mozan._id} value={mozan._id} selected = {mozan}>
                          {mozan.firstName + " " + mozan.lastName}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
                
                <FormGroup style = {{marginTop: "10px"}} row>
                  <Label sm={12}>Namaz Timings</Label>
                  <Col sm={12}>
                    {timing.map((tim, index) => (
                      <Row style={{marginTop:"8px"}} key={`${tim._id}-${index}`}>
                        <Col sm={4}>
                          <Input
                            type="select"
                            value={tim.namazName}
                            onChange={e =>
                              handleTimingChange(index, 'namazName', e.target.value)
                            }
                          >
                            <option value="">Select Namaz</option>
                            <option value="Fajr">Fajr</option>
                            <option value="Zuhr">Zuhr</option>
                            <option value="Asr">Asr </option>
                            <option value="Maghrib">Maghrib</option>
                            <option value="Isha">Isha</option>
                            <option value="Jumah Qhutba">Jumah Qhutba</option>
                          </Input>
                        </Col>
                        <Col sm={2}>
                          <Input
                            type="number"
                            placeholder="Hours"
                            min="1"
                            max="12"
                            value={tim.hours}
                            onChange={e =>
                              handleTimingChange(index, 'hours', e.target.value)
                            }
                          />
                        </Col>
                        <Col sm={2}>
                          <Input
                            type="number"
                            placeholder="Minutes"
                            min="0"
                            max="59"
                            value={tim.minutes}
                            onChange={e =>
                              handleTimingChange(index, 'minutes', e.target.value)
                            }
                          />
                        </Col>
                        <Col sm={2}>
                          <Input
                            type="select"
                            value={tim.ampm}
                            onChange={e =>
                              handleTimingChange(index, 'ampm', e.target.value)
                            }
                          >
                            <option value="">Select AM/PM</option>
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </Input>
                        </Col>
                        <Col sm={1}>
                          <Button color="danger" onClick={() => handleRemoveTiming(index)}>
                            <FaTrash />
                          </Button>
                        </Col>
                      </Row>
                    ))}
                    <Button style={{marginTop: "10px"}} color="primary" onClick={handleAddTiming}>
                      Add Timing
                    </Button>
                  </Col>
                </FormGroup>
                <FormGroup style= {{display: "Flex", justifyContent: "space-around"}}>
                    <Button>Update</Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      }
    </Page>
  );
};

export default withRouter(EditMasjid);
