import logo200Image from 'assets/img/logo/logo_200.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-12.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import {
  MdDashboard,
  MdWeb,
} from 'react-icons/md';
import { RiLockPasswordLine } from "react-icons/ri";
import { NavLink } from 'react-router-dom';
import {
  Button,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navItems = [
  {
    to: '/',
    name: 'Masjids',
    exact: true,
    Icon: MdDashboard,
    isVendor: false,
  },
  
  { to: '/users', name: 'Users', exact: false, Icon: MdWeb, isVendor: false },
  { to: '/change/password', name: 'pasword', exact: false, Icon: RiLockPasswordLine, isVendor: false },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  isVendorActive = JSON.parse(localStorage.getItem('@is_Vendor'));

  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                src={logo200Image}
                width="150"
                height="40"
                className="pr-2 img-fluid"
                alt=""
              />
            </SourceLink>
            <h5>Masjid Nearby</h5>
          </Navbar>
          <Nav vertical>
            {navItems
              .filter(({ isVendor }) => isVendor === this.isVendorActive)
              .map(({ to, name, exact, Icon, isVendor }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeclassname="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}

            <NavItem key={'asdasdasdasd'} className={bem.e('nav-item')}>
              <Button
                id={`navItem-logout`}
                className="text-uppercase"
                activeclassname="active"
                onClick={() => {
                  localStorage.removeItem('@auth_token');
                  localStorage.removeItem('@is_Vendor');
                  window.location.reload();
                }}
              >
                <span className="">Log Out</span>
              </Button>
            </NavItem>
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
