import axios from 'axios';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa'; // Import trash icon for deleting timing
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Spinner,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import toast from 'react-hot-toast';
import { withRouter} from 'react-router-dom';
import UploadAndDisplayImage from 'components/UploadAndDisplayImage';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Switch from 'react-switch';

const AddMasjid = () => {
  const [imams, setImams] = useState([]);
  const [mozans, setMozans] = useState([]);
  const [name, setName] = useState(null);
  const [address, setAddress] = useState(null);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [isApproved, setIsApproved] = useState(false);
  const [timing, setTiming]  = useState([]);
  const [loading, setLoading] = useState(true);
  const [imam, setImam]  = useState("");
  const [mozan, setMozan] = useState("");
  const [icon, setIcon] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [imageLoader, setImageLoader] = useState(false);
  const [banners, setBanner] = useState([]);
  const [modal, setModal] = useState(false);
  const [imageType, setImageType] = useState("");

  useEffect(() => {
    const fetchImamAndMozans = async () => {
        try {
            const {data}  = await axios.get(`/admin/all/imamsmoazn`);
            if (data.error) {
                toast.error(data.message);
            } else {
                if (data.error) {
                    toast.error(data.message);
                } else {
                    setMozans(data.payload.mozans)
                    setImams(data.payload.imams)
                }
            }
        } catch (error) {
          if (error && error.message && String(error.message).includes('401')) {
            localStorage.removeItem('@auth_token');
            localStorage.removeItem('@is_Vendor');
            window.location.reload();
          }
          else{
            toast.error("Something went wrong");
            console.log(error)
          }
        } finally {
          setLoading(false);
        }
    };

    fetchImamAndMozans();
  }, []);

  const handleTimingChange = (index, field, value) => {
    const updatedTiming = [...timing];
    if(field == "namazName"){
      if(updatedTiming.find(x => value == x.namazName)){
        toast.error(value+" Namaz Time Already Exist");
      }
      else{
        updatedTiming[index][field] = value;
        setTiming(updatedTiming);
      }
    }
    else{
      updatedTiming[index][field] = value;
      setTiming(updatedTiming);
    }
  };

  const handleAddTiming = () => {
    const newTiming = {
      _id: uuidv4(),
      namazName: '',
      hours: '',
      minutes: '',
      ampm: '',
    };
  
    setTiming(prevTiming => [...prevTiming, newTiming]);
  };

  const handleRemoveTiming = index => {
    setTiming(prevTiming => prevTiming.filter((_, i) => i !== index));
  };

  const AddMasjid = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      let namazTimings = [];
      timing.forEach(x=> {
        if(x.namazName && x.hours && x.minutes && x.ampm){
          namazTimings.push({
            "namazName": x.namazName,
            "namazTime": x.hours+":"+x.minutes+" "+x.ampm
          })
        }
      });
      let payload = {
        imam,
        mozan,
        masjidName: name,
        address,
        lat,
        long,
        icon,
        banners,
        namazTimings,
        isApproved: isApproved ? true :false,
      }
     
        const { data } = await axios.post('/admin/create/masjid', payload);
        if (data.error) {
          setLoading(false)
          return toast.error(data.message);
        }
        else {
          setLoading(false)
          toast.success(data.message);
          window.location.href = "/"
        } 
      } catch (error) {
        setLoading(false);
        if (error && error.message && String(error.message).includes('401')) {
          localStorage.removeItem('@auth_token');
          localStorage.removeItem('@is_Vendor');
          window.location.reload();
        }
        else{
          toast.error("Something went wrong");
          console.log(error)
        }
      }
  };

  const toggleModal = () => {
    setImageType("banner")
    setModal(!modal);
  };

  const toggleIconModal = () => {
    setImageType("icon")
    setModal(!modal);
  };

  const addPhoto = async () => {
    try {
      if(!newImage){
        toast.error('Image is required');
      }
      else{
        if(imageType == "icon"){
            setIcon(newImage)
        }
        else{
           setBanner([...banners, newImage])
        }
        setImageType("")
        setNewImage('');
        setModal(false);
      }
    } catch (error) {
      setImageType("icon")
      console.error('Error:', error);
      toast.error('Failed to add');
    }
  };

  const handleRemoveBanner = async (photo, index) => {
    try {
      if(!photo){
        toast.error('Image is required');
      }
      else{
        let m = banners.filter(x => x !== photo);
        setBanner(m)
        toast.success(data.message);
        setMasjid(data.payload);
      }
    } catch (error) {
      console.error('Error adding photo:', error);
      toast.error('Failed to delete photo');
    }
  };

  return (
    <Page
      title="Add Masjid"
      breadcrumbs={[{ name: 'tables', active: true }]}
      className="AddMasjid"
    >
      {loading ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <Spinner color="primary" />
          </div>
        ):
      <Row>
        <Col>
          <Card>
            <CardHeader>Add Masjid</CardHeader>
            <CardBody>
              <Form onSubmit={AddMasjid}>
              <FormGroup style={{display:"flex", alignItems: "center", justifyContent:"flex-end"}} check>
                    <Label style={{marginRight : "10px"}} check>
                    {isApproved? "Active": "Inactive"}
                    </Label>
                    <Switch
                        onChange={() => setIsApproved(!isApproved)}
                        checked={isApproved}
                      />
              </FormGroup>
              <FormGroup row>
                  <Label for="name" sm={3}>
                    Name
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Name"
                      onChange={e => setName(e.target.value)}
                      value={name}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="address" sm={3}>
                    Address
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="address"
                      placeholder="Address"
                      onChange={e => setAddress(e.target.value)}
                      value={address}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="lat" sm={3}>
                    Latitude
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="lat"
                      placeholder="Latitude"
                      onChange={e => setLat(e.target.value)}
                      value={lat}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="long" sm={3}>
                    Longitude
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="long"
                      placeholder="Longitude"
                      onChange={e => setLong(e.target.value)}
                      value={long}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>Imam</Label>
                  <Col sm={7}>
                    <Input
                      type="select"
                      value={imam}
                      onChange={e => setImam(e.target.value)}
                    >
                      <option value="">Select Imam</option>
                      {imams.map(imam => (
                        <option key={imam._id} value={imam._id} selected = {imam}>
                          {imam.firstName + " " + imam.lastName}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>Mozan</Label>
                  <Col sm={7}>
                    <Input
                      type="select"
                      value={mozan}
                      onChange={e => setMozan(e.target.value)}
                    >
                      <option value="">Select Mozan</option>
                      {mozans.map(mozan => (
                        <option key={mozan._id} value={mozan._id} selected = {mozan}>
                          {mozan.firstName + " " + mozan.lastName}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
                
                <FormGroup style = {{marginTop: "10px"}} row>
                  <Label sm={12}>Namaz Timings</Label>
                  <Col sm={12}>
                    {timing.map((tim, index) => (
                      <Row style={{marginTop:"8px"}} key={`${tim._id}-${index}`}>
                        <Col sm={4}>
                          <Input
                            type="select"
                            value={tim.namazName}
                            onChange={e =>
                              handleTimingChange(index, 'namazName', e.target.value)
                            }
                          >
                            <option value="">Select Namaz</option>
                            <option value="Fajr">Fajr</option>
                            <option value="Zuhr">Zuhr</option>
                            <option value="Asr">Asr </option>
                            <option value="Maghrib">Maghrib</option>
                            <option value="Isha">Isha</option>
                            <option value="Jumah Qhutba">Jumah Qhutba</option>
                          </Input>
                        </Col>
                        <Col sm={2}>
                          <Input
                            type="number"
                            placeholder="Hours"
                            min="1"
                            max="12"
                            value={tim.hours}
                            onChange={e =>
                              handleTimingChange(index, 'hours', e.target.value)
                            }
                          />
                        </Col>
                        <Col sm={2}>
                          <Input
                            type="number"
                            placeholder="Minutes"
                            min="0"
                            max="59"
                            value={tim.minutes}
                            onChange={e =>
                              handleTimingChange(index, 'minutes', e.target.value)
                            }
                          />
                        </Col>
                        <Col sm={2}>
                          <Input
                            type="select"
                            value={tim.ampm}
                            onChange={e =>
                              handleTimingChange(index, 'ampm', e.target.value)
                            }
                          >
                            <option value="">Select AM/PM</option>
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </Input>
                        </Col>
                        <Col sm={1}>
                          <Button color="danger" onClick={() => handleRemoveTiming(index)}>
                            <FaTrash />
                          </Button>
                        </Col>
                      </Row>
                    ))}
                    <Button style={{marginTop: "10px"}} color="primary" onClick={handleAddTiming}>
                      Add Timing
                    </Button>
                  </Col>
                </FormGroup>

                <Row>
                <Col>
                    <Card className="mt-4">
                    <div style={{display:"flex", justifyContent: "space-between", margin: "20px", marginBottom: "0"}}>
                        <h5 style={{
                            height: 'fit-content',
                            marginTop: "10px",
                            fontSize: "16px"
                        }}>ICON</h5>
                        <Button color="primary" className="ml-auto" onClick={toggleIconModal}>
                            {(icon ? "Update Icon": "Add Icon")}
                        </Button>
                    </div>
                    <CardBody>
                        <div className="img-grid">
                        {(icon) && 
                            <Card className="photo-card">
                            <img src={icon} alt={`Masjid Icon`} className="photo" />
                            <Button color="danger" onClick={() => setIcon("")}>
                                <FaTrash />
                            </Button>
                            </Card>}
                        </div>
                    </CardBody>
                    </Card>
                </Col>
                </Row>
                <Row>
                <Col>
                    <Card className="mt-4">
                    <div style={{display:"flex", justifyContent: "space-between", margin: "20px", marginBottom: "0"}}>
                        <h5 style={{
                            height: 'fit-content',
                            marginTop: "10px",
                            fontSize: "16px"
                        }}>BANNERS</h5>
                        <Button color="primary" className="ml-auto" onClick={toggleModal}>
                        Add Banner
                        </Button>
                    </div>
                    <CardBody>
                        <div className="img-grid">
                        {banners?.map((photo, index) => (
                            <Card key={index} className="photo-card">
                            <img src={photo} alt={`Masjid ${index}`} className="photo" />
                            <Button color="danger" onClick={() => handleRemoveBanner(photo, index)}>
                                <FaTrash />
                            </Button>
                            </Card>
                        ))}
                        </div>
                    </CardBody>
                    </Card>
                </Col>
                </Row>

                <FormGroup style= {{display: "Flex", justifyContent: "space-around", marginTop: "10px"}}>
                    <Button>Submit</Button>
                </FormGroup>
              </Form>
            </CardBody>
            <Modal isOpen={modal} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}>Add Photo</ModalHeader>
              <ModalBody>
              {imageLoader ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner color="primary" />
                </div>
                ):(
                <UploadAndDisplayImage url={newImage} setUrl={setNewImage} setImageLoader={setImageLoader} />
                )
              }
              </ModalBody>
              <ModalFooter>
                {newImage && <Button color="primary" onClick={addPhoto}>
                  Add
                </Button>}{' '}
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </Card>
        </Col>
      </Row>
      }
       <style jsx>{`
        .img-grid {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
        }

        .photo-card {
          width: calc(33.333% - 10px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        .photo-card .photo {
          width: 100%;
          height: 150px;
          object-fit: cover;
        }
      `}</style>
    </Page>
  );
};

export default withRouter(AddMasjid);
