import axios from 'axios';
import Page from 'components/Page';
import { FaTrash } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem 
} from 'reactstrap';
import toast from 'react-hot-toast';
import { withRouter} from 'react-router-dom';
import UploadAndDisplayImage from 'components/UploadAndDisplayImage';
import { useLocation } from 'react-router-dom';

const MasjidDetail = () => {
  const [masjid, setMasjid] = useState({});
  const [newImage, setNewImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);
  const [imageType, setImageType] = useState("");
  const [imageLoader, setImageLoader] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const masjidId = queryParams.get('masjid');

  useEffect(() => {
    const fetchMasjid = async () => {
      if (masjidId) {
        try {
          const { data } = await axios.get(`/admin/masjid/${masjidId}`);
          if (data.error) {
            setError(data.message);
          } else {
            setMasjid(data.payload);
          }
        } catch (error) {
          if (error && error.message && String(error.message).includes('401')) {
            localStorage.removeItem('@auth_token');
            localStorage.removeItem('@is_Vendor');
            window.location.reload();
          }
          else{
            toast.error("Something went wrong");
            console.log(error)
          }
        } finally {
          setLoading(false);
        }
      }
    };

    fetchMasjid();
  }, [masjidId]);

  const addPhoto = async () => {
    try {
      if(!newImage){
        toast.error('Image is required');
      }
      else{
        let data;
        if(imageType == "icon"){
            data = await axios.post(`/admin/update/icon/masjid/${masjid?._id}`, {
            photoUrl: newImage,
          });
        }
        else{
            data = await axios.post(`/admin/add/photos/masjid/${masjid?._id}`, {
            photoUrl: newImage,
          });
        }
        data = data.data;
        setImageType("")
        toast.success(data.message);
        setMasjid(data.payload);
        setNewImage('');
        setModal(false);
      }
    } catch (error) {
      setImageType("")
      console.error('Error:', error);
      toast.error('Failed to add');
      if (error && error.message && String(error.message).includes('401')) {
        localStorage.removeItem('@auth_token');
        localStorage.removeItem('@is_Vendor');
        window.location.reload();
      }
      else{
        toast.error("Something went wrong");
        console.log(error)
      }
    }
  };

  const handleRemovePhoto = async (photo, index) => {
    try {
      if(!photo){
        toast.error('Image is required');
      }
      else{
        const { data } = await axios.post(`/admin/remove/photos/masjid/${masjid?._id}`, {
          photoUrl: photo,
        });
        toast.success(data.message);
        setMasjid(data.payload);
      }
    } catch (error) {
      setLoading(false);
      if (error && error.message && String(error.message).includes('401')) {
        localStorage.removeItem('@auth_token');
        localStorage.removeItem('@is_Vendor');
        window.location.reload();
      }
      else{
        console.error('Error adding photo:', error);
        toast.error("Something went wrong");
      }
    };
  }


  const toggleModal = () => {
    setImageType("banner")
    setModal(!modal);
  };

  const toggleIconModal = () => {
    setImageType("icon")
    setModal(!modal);
  };

  return (
    <Page title="Masjid Details" breadcrumbs={[{ name: 'tables', active: true }]}>
      <Container className="MasjidDetail">
        {loading ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <Spinner color="primary" />
          </div>
        ) : error ? (
          <div className="text-center">
            <p className="text-danger">{error}</p>
          </div>
        ) : (
          <>
            <Row>
              <Col>
                <Card className="mb-3 custom-card">
                <div style={{borderBottom: "3px solid rgb(212, 212, 212)", display:"flex", justifyContent: "space-between", margin: "20px", marginBottom: "0"}}>
                    <h5 style={{
                        height: 'fit-content',
                        marginTop: "10px",
                        fontSize: "16px"
                      }}>DETAILS</h5>
                    <Button color="primary" className="ml-auto" onClick = { () => { window.location.href ='/edit/masjid/details?masjid='+masjid._id}}>
                      Edit
                    </Button>
                  </div>
                  <CardBody>
                    <Row>
                      <Col>
                        <h6>Name</h6>
                        <p className="text-muted">{masjid?.masjidName}</p>
                      </Col>
                      <Col>
                        <h6>Address</h6>
                        <p className="text-muted">{masjid?.address}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h6>Imam</h6>
                        <p className="text-muted">{masjid?.imam?.name}</p>
                      </Col>
                      <Col>
                        <h6>Mozan</h6>
                        <p className="text-muted">{masjid?.mozan?.name}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h6>Latitude</h6>
                        <p className="text-muted">{masjid?.location?.coordinates[1]}</p>
                      </Col>
                      <Col>
                        <h6>Longitude</h6>
                        <p className="text-muted">{masjid?.location?.coordinates[0]}</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="mt-4">
                  {(masjid?.isAddedBy && masjid?.isAddedBy == "admin") ? (
                    <div style={{display:"flex", justifyContent: "space-between", margin: "20px", marginBottom: "0"}}>
                    <h5 style={{
                        height: 'fit-content',
                        marginTop: "10px",
                        fontSize: "16px"
                      }}>Masjid Added By Admin</h5>
                  </div>
                   ) : (
                    <>
                  <div style={{display:"flex", justifyContent: "space-between", margin: "20px", marginBottom: "0"}}>
                    <h5 style={{
                        height: 'fit-content',
                        marginTop: "10px",
                        fontSize: "16px"
                      }}>Masjid Added By</h5>
                  </div>
                  <CardBody>
                  <Row>
                      <Col>
                        <h6>Name</h6>
                        <p className="text-muted">{masjid?.addedByName}</p>
                      </Col>
                      <Col>
                        <h6>Phone</h6>
                        <p className="text-muted">{masjid?.addedByPhone}</p>
                      </Col>
                    </Row>
                    </CardBody>
                    </>
                  )}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="mt-4">
                  <div style={{display:"flex", justifyContent: "space-between", margin: "20px", marginBottom: "0"}}>
                    <h5 style={{
                        height: 'fit-content',
                        marginTop: "10px",
                        fontSize: "16px"
                      }}>Masjid Timings</h5>
                  </div>
                  <ListGroup flush>
                    {masjid.namazTimings.map((timing) => (
                      <ListGroupItem key={timing._id}>
                        <span className="font-weight-bold">{timing.namazName}:</span>{' '}
                        {timing.namazTime}
                      </ListGroupItem>
                    ))}
                    </ListGroup>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="mt-4">
                  <div style={{display:"flex", justifyContent: "space-between", margin: "20px", marginBottom: "0"}}>
                    <h5 style={{
                        height: 'fit-content',
                        marginTop: "10px",
                        fontSize: "16px"
                      }}>ICON</h5>
                    <Button color="primary" className="ml-auto" onClick={toggleIconModal}>
                      {(!masjid.icon) ? "Add Icon": "Update Icon"}
                    </Button>
                  </div>
                  <CardBody>
                    <div className="img-grid">
                    {(masjid.icon) && 
                        <Card className="photo-card">
                          <img src={masjid.icon} alt={`Masjid Icon`} className="photo" />
                        </Card>}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="mt-4">
                  <div style={{display:"flex", justifyContent: "space-between", margin: "20px", marginBottom: "0"}}>
                    <h5 style={{
                        height: 'fit-content',
                        marginTop: "10px",
                        fontSize: "16px"
                      }}>BANNERS</h5>
                    <Button color="primary" className="ml-auto" onClick={toggleModal}>
                      Add Banner
                    </Button>
                  </div>
                  <CardBody>
                    <div className="img-grid">
                      {masjid?.banners?.map((photo, index) => (
                        <Card key={index} className="photo-card">
                          <img src={photo} alt={`Masjid ${index}`} className="photo" />
                          <Button color="danger" onClick={() => handleRemovePhoto(photo, index)}>
                            <FaTrash />
                          </Button>
                        </Card>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal isOpen={modal} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}>Add Photo</ModalHeader>
              <ModalBody>
              {imageLoader ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner color="primary" />
                </div>
                ):(
                <UploadAndDisplayImage url={newImage} setUrl={setNewImage} setImageLoader={setImageLoader} />
                )
              }
              </ModalBody>
              <ModalFooter>
                {newImage && <Button color="primary" onClick={addPhoto}>
                  Add
                </Button>}{' '}
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </>
        )}
      </Container>
      <style jsx>{`
        .MasjidDetail .img-grid {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
        }

        .MasjidDetail .photo-card {
          width: calc(33.333% - 10px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        .MasjidDetail .photo {
          width: 100%;
          height: 150px;
          object-fit: cover;
        }

        .ml-auto {
          margin-left: auto;
        }
        .custom-card {
          border: none;
          border-radius: 10px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          background-color: #ffffff;
        }

        .custom-card-header {
          background-color: #f8f9fa;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        .MasjidDetail .list-group-item {
          border: none;
          padding: 0.75rem 1.25rem;
        }

        .MasjidDetail .list-group-item:nth-child(odd) {
          background-color: #f8f9fa;
        }

        .MasjidDetail .list-group-item .font-weight-bold {
          color: #333;
        }
      `}</style>
    </Page>
  );
};

export default withRouter(MasjidDetail);
