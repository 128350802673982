import React from 'react';
import PropTypes from 'utils/propTypes';

import bn from 'utils/bemnames';

import { Breadcrumb, BreadcrumbItem, Button, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import Typography from './Typography';

const bem = bn.create('page');

const Page = ({
  title,
  breadcrumbs,
  tag: Tag,
  className,
  children,
  isButton,
  onPress,
  buttonText,
  isLinkButton,
  linkButton,
  ...restProps
}) => {
  const classes = bem.b('px-3', className);

  return (
    <div {...restProps}>
      <div className={'d-flex justify-content-between px-4'}>
        {/* {title && typeof title === 'string' ? (
            
          ) : (
            title
          )} */}
        {/* {breadcrumbs && (
            <Breadcrumb className={bem.e('breadcrumb')}>
              <BreadcrumbItem>Home</BreadcrumbItem>
              {breadcrumbs.length &&
                breadcrumbs.map(({ name, active }, index) => (
                  <BreadcrumbItem key={index} active={active}>
                    {name}
                  </BreadcrumbItem>
                ))}
            </Breadcrumb>
          )} */}

        <Typography type="h1" className={bem.e('title')}>
          {title}
        </Typography>
        {isButton && (
          <Button outline onClick={onPress} className="px-2">
            {buttonText}
          </Button>
        )}
        {isLinkButton && <>{linkButton()}</>}
      </div>
      {children && <Container>{children}</Container>}
    </div>
  );
};

Page.propTypes = {
  tag: PropTypes.component,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
};

Page.defaultProps = {
  tag: 'div',
  title: '',
};

export default withRouter(Page);
